
  <template>
  <el-row>
    <el-col :span="24">
        <div class="zone">
            <h4>品牌联盟</h4>
            <div class="content">
                    <div v-for="(item,index) in arr" :key="index">
                            <a :href="item.link" :title="item.title" target="_blank">
                                    <img :src="item.src" :alt="item.title" :title="item.title">
                            </a>    
                    </div>
            </div>
        </div>
    </el-col>
   
  </el-row>
</template>

<style lang="less" scoped>
@import '~style/index.less';

.zone {
    margin-top: 30px;
    overflow: hidden;
    background: #fff;
    h4 {
        padding: 10px 0px 10px 10px;
        line-height: 30px;
        margin-bottom: 20px;
        font-size: 15px;
        border-width: 0 0 1px 0;
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 20px;
    }
    .content {
       display: flex;
      //  height: 100px;
       flex: 1;
       border-bottom: 1px solid #e8e8e8;
       padding-bottom: 20px;
       box-sizing: content-box;
       &>div {
             a {
                    // width: 100px;
                    height: 66px;
                    display: inline-block;
                    padding: 10px;
                    border: 1px solid #e8e8e8!important;
                    border-left: none!important;
                    box-sizing: content-box;
            img {
                    width: 100%;
                    display: inline-block; 
                    opacity:1; 
                }
            }
       }
      
      
       &>div:last-child img{
           border-right: none!important;
       }
       &>div:hover{
           border: 1px solid #efe7e7;
           height: 87px;
       }
        &>div:hover img {
             opacity:1; 
        }
    }
}
   
</style>

<script>
import FSGICON from "@/assets/AssociatedDrugFirms/fsg.jpg";
import WTICON from "@/assets/AssociatedDrugFirms/jiliwantong.jpg";
import ZHCICON from "@/assets/AssociatedDrugFirms/zhanghenchun.jpg";
import ZDICON from "@/assets/AssociatedDrugFirms/zhendong.jpg";
import KZTICON from "@/assets/AssociatedDrugFirms/kangzhite.jpg";
import LKYYICON from "@/assets/AssociatedDrugFirms/lukangyiyao.jpg";
import KYDICON from "@/assets/AssociatedDrugFirms/kangyouduo.jpg";
import BKTICON from "@/assets/AssociatedDrugFirms/baikuntang.jpg";
import HLICON from "@/assets/AssociatedDrugFirms/haliu.jpg";
import SFICON from "@/assets/AssociatedDrugFirms/sf.jpg";
export default {
    name:"ShopArea",
    data(){
        return {
           arr:[
               {
                   link:"http://www.sxfsg.com/",
                   title:"复盛公",
                   src:FSGICON
               },
                 {
                   link:"https://www.3akx.cn/",
                   title:"万通",
                   src:WTICON
                },
                 {
                   link:"http://www.zhanghengchun.com/",
                   title:"张恒春",
                   src:ZHCICON
                },
                {
                   link:"http://www.zdjt.com/",
                   title:"振东",
                   src:ZDICON
                },
                 {
                   link:"http://3a.3akx.com/",
                   title:"抗之特",
                   src:KZTICON
                },
                  {
                   link:"http://www.lkpc.com/",
                   title:"鲁抗",
                   src:LKYYICON
                },
                 {
                   link:"http://3a.3akx.com/",
                   title:"康又多",
                   src:KYDICON
                },
                 {
                   link:"http://3a.3akx.com/",
                   title:"百坤堂",
                   src:BKTICON
                },
                   {
                   link:"http://3a.3akx.com/",
                   title:"哈中药6",
                   src:HLICON
                },
                  {
                   link:"https://www.sf-express.com/cn/sc/dynamic_function/waybill/",
                   title:"顺丰",
                   src:SFICON
                },
           ]
        }
    },
    methods:{
           
    }
};
</script>
  
